/* styles.module.css */
html,
body,

#root {
    height: 100%;
    margin: 0;
    padding: 0;
  }

.app {
    height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  justify-content: normal;
  align-items: center;
  padding: 10px 20px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.admin {
  display: flex;
  align-items: right;
  margin-left: auto;
}

.branding {
    display: flex;
    align-items: center;
}

.branding svg {
    width: 200px;
    /* Adjust the width according to your logo size */
    height: auto;
    margin: 0 20px;
}

.button {
    background-color: #4a4a4a;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    margin: 0 20px;
}

.button:hover {
  background-color: #5da7dd;
  color: white;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal {
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    max-width: 50%;
    outline: none;
  }

.modal h2 {
    margin: 0 0 20px 0;
}

.modal button {
    align-self: flex-end;
    background-color: #4a4a4a;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
}

.resetButton {
  background-color: #1c87c9;
  border: none;
  color: white;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  padding: 10px 24px;
  margin: 5px;
  border-radius: 5px;
}

.resetButton:hover {
  background-color: #5da7dd;
  color: white;
}

.resetButton:disabled {
  background-color: #c0c0c0;
  color: #808080;
  cursor: not-allowed;
}

.playground {
    display: flex;
    width: 100%;
    height: 90vh;
}

.betaLabel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ff9800;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 12px;
  padding: 2px 8px;
  margin-left: 8px;
}

.authHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.authHeader svg {
  width: 200px;
  height: auto;
  margin: 0 20px;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 16px;
}